<template>
    <section id="artigos-e-noticias" class="artigos">
        
        <div class="container padding-y4">
            
            <div class="row">
                <div class="col-md-12 margin-b2">
                    <h1 class="com-barra text-center"><strong>{{ titulo }}</strong></h1>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4 padding-x1 margin-y1" v-for="(artigo, artigo_i) in artigos" :key="artigo_i" v-show="(!resumo) || (artigo_i < 3)">
                    <div class="artigo padding-y1 padding-x3">
                        <h3>{{ artigo.titulo }}</h3>
                        <p>{{ artigo.texto }}</p>
                        <router-link :to="{ name: 'artigo', params: {id: '123'} }">O ARTIGO COMPLETO >></router-link>
                    </div>
                </div>
            </div>

            <div class="row" v-show="resumo">
                <div class="col-md-12 padding-y1 d-flex justify-content-center">
                    <button class="btn" @click="vermais">Ver mais</button>
                </div>
            </div>

        </div>
        
    </section>
</template>
<script>
export default {
    props: {
        titulo: {
            type: String,
            default: 'Artigos e Notícias'
        }    
    },

    data: () => {
        return {
            resumo: true,
            artigos: [
                {
                    titulo: 'Lorem ipsum dolor sit amet conse tetur #1',
                    texto: 'Proin fermentum, tortor vel varius iaculis, arcu libero consequat odio, nec efficitur mauris urna volutpat nunc. Morbi eu nisl ultricies mi lobortis porttitor. Vestibulum semper et Aliquam pretium finibus dui.',
                    link: '#'
                },
                {
                    titulo: 'Lorem ipsum dolor sit amet conse tetur #2',
                    texto: 'Proin fermentum, tortor vel varius iaculis, arcu libero consequat odio, nec efficitur mauris urna volutpat nunc. Morbi eu nisl ultricies mi lobortis porttitor. Vestibulum semper et Aliquam pretium finibus dui.',
                    link: '#'
                },
                {
                    titulo: 'Lorem ipsum dolor sit amet conse tetur #3',
                    texto: 'Proin fermentum, tortor vel varius iaculis, arcu libero consequat odio, nec efficitur mauris urna volutpat nunc. Morbi eu nisl ultricies mi lobortis porttitor. Vestibulum semper et Aliquam pretium finibus dui.',
                    link: '#'
                },
                {
                    titulo: 'Lorem ipsum dolor sit amet conse tetur #4',
                    texto: 'Proin fermentum, tortor vel varius iaculis, arcu libero consequat odio, nec efficitur mauris urna volutpat nunc. Morbi eu nisl ultricies mi lobortis porttitor. Vestibulum semper et Aliquam pretium finibus dui.',
                    link: '#'
                },
                {
                    titulo: 'Lorem ipsum dolor sit amet conse tetur #5',
                    texto: 'Proin fermentum, tortor vel varius iaculis, arcu libero consequat odio, nec efficitur mauris urna volutpat nunc. Morbi eu nisl ultricies mi lobortis porttitor. Vestibulum semper et Aliquam pretium finibus dui.',
                    link: '#'
                },
                {
                    titulo: 'Lorem ipsum dolor sit amet conse tetur #6',
                    texto: 'Proin fermentum, tortor vel varius iaculis, arcu libero consequat odio, nec efficitur mauris urna volutpat nunc. Morbi eu nisl ultricies mi lobortis porttitor. Vestibulum semper et Aliquam pretium finibus dui.',
                    link: '#'
                },
            ]
        }
    },

    methods: {

        vermais(){
            this.resumo = false;
        }
    }
}
</script>